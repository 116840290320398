import React from "react";
import { ModuleWrapper } from "../moduleWrapper";

const TwoColRow = ({ firstTitle, firstValue, secondTitle, secondValue }) => {
  return (
    <div className="flex flex-col lg:grid grid-cols-2 lg:mb-20 gap-x-36">
      <div className="col-start-1 col-end-2 border-b-2 pb-10">
        <h1 className="text-lg text-gray-500 mb-4">{firstTitle}</h1>
        <p className="text-8xl text-red">{firstValue}</p>
      </div>
      <div className="col-start-2 col-end-3 border-b-2  pb-10">
        <h1 className="text-lg text-gray-500 mb-4">{secondTitle}</h1>
        <p className="text-8xl text-red">{secondValue}</p>
      </div>
    </div>
  );
};

const StatsGrid = ({ config, twoColumnRow, singleColumnRow }) => {
  return (
    <ModuleWrapper className="px-gutter" {...config}>
      <div>
        {twoColumnRow.map((item, i) => (
          <TwoColRow {...item} key={i} />
        ))}
      </div>
      <div>
        {singleColumnRow.map((item, i) => (
          <div className="border-b-2 pb-10">
            <h1 className="text-lg text-gray-500 mb-4">{item.title}</h1>
            <p className="text-8xl text-red">{item.value}</p>
          </div>
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default StatsGrid;
